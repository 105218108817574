import logo from '../../assets/images/CARR Black.png'

type Props = {
  loader?: boolean | string
  noData?: boolean | string
  style?: object
}

function LoaderBox({ loader, noData, style }: Props) {
  return (
    <>
      {loader && (
        <div className="loading-content" style={style}>
          <img src={logo} alt="Loading" />
          <h1>{loader === true ? 'Loading..' : loader}</h1>
        </div>
      )}
      {!loader && noData && (
        <div className="loading-content" style={style}>
          <img src={logo} alt="No Data found" />
          <h1>{noData === true ? 'No Data found..' : noData}</h1>
        </div>
      )}
    </>
  )
}

export default LoaderBox
